import GOLD_GRADIENT from "../img/color/gold_gradient.jpg"
import SILVER_GRADIENT from "../img/color/silver_gradient.jpg"

export const GRAY = {id : 1, name : 'gray', color : '#808080'};
export const BLACK = {id : 2, name : 'black', color : '#000'};
export const WHITE = {id : 3, name : 'white', color : '#fff'};
export const BROWN = {id : 4, name : 'brown', color : '#4a3226'};
export const ORANGE = {id : 5, name : 'orange', color : '#FF780D'};
export const YELLOW = {id : 6, name : 'yellow', color : '#FFCF09'};
export const DARK_GREEN = {id : 7, name : 'dark green', color : '#022D00'};
export const GREEN = {id : 8, name : 'green', color : '#339F3A'};
export const LEMONY = {id : 9, name : 'lemony', color : '#FEF898'};
export const LIGHT_GREEN = {id : 10, name : 'light green', color : '#99FF99'};
export const KHAKI = {id : 11, name : 'khaki', color : '#4A521F'};
export const DARK_BLUE = {id : 12, name : 'dark blue', color : '#0A0A3C'};
export const BLUE = {id : 13, name : 'blue', color : '#0000FF'};
export const LIGHT_BLUE = {id : 14, name : 'light blue', color : '#74C5F4'};
export const AQUA = {id : 15, name : 'aqua', color : '#027f83'};
export const RED = {id : 16, name : 'red', color : '#F80406'};
export const PINK = {id : 17, name : 'pink', color : '#FEAEC9'};
export const BURGUNDY = {id : 18, name : 'burgundy', color : '#4E0204'};
export const PURPLE = {id : 19, name : 'purple', color : '#7401F2'};
export const LILAC = {id : 20, name : 'lilac', color : '#D8C7FB'};
export const TURQUOISE = {id : 21, name : 'turquoise', color : '#78C9CA'};
export const GOLD = {id : 22, name : 'gold', color : 'url('+GOLD_GRADIENT+') repeat'};
export const SILVER = {id : 23, name : 'silver', color : 'url('+SILVER_GRADIENT+') repeat'};