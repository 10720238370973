/** TODO: Eslint ругался на отсутствие возвращаемого значения */
export function pushIfNotExist(id, list, state){
    let person = list.find(el => el.id === id);
    if(person !== undefined && state.find(el => el.id === person.id) === undefined){
        state.push(person).order((a, b) => a > b);
    }
    return true;
}
/** если надо будет добавлять список =) */
export function pushIfNotExistByList(ids, list, state){
    list.filter(el => ids.indexOf(el.id) !== -1).map(function(el){
        /** Добавляем только элементы - которых ещё нет в состоянии */
        if(state.find(stateEl => stateEl.id === el.id) === undefined){
            state.push(el);
        }
        return false
    });
    return true;
}