import genry_tryman_1 from '../../../audio/genry_tryman_1.mp3';
import genry_tryman_2 from '../../../audio/genry_tryman_2.mp3';
import genry_tryman_3 from '../../../audio/genry_tryman_3.mp3';
import genry_tryman_4 from '../../../audio/genry_tryman_4.mp3';
import genry_tryman_5 from '../../../audio/genry_tryman_new_5.mp3';
import genry_tryman_6 from '../../../audio/genry_tryman_6.mp3';

import genry_tryman_img from "../../../img/persons/genry_tryman.png";
import genry_tryman_color_img from "../../../img/persons/genry_tryman_color.jpg";

const GENRY_TRYMAN = {
    id : 5,
    path : 'genry_tryman',
    name : 'Генри Труман',
    position : 'Бывший совладелец парка. Брат Виктора',
    isCriminal : false,
    img : genry_tryman_img,
    imgColor : genry_tryman_color_img,
    interrogation : [
        {
            id : 1,
            title : 'interrogations.interrogation51',
            text : 'Можно сказать, что я осуществил свою давнюю мечту. Еще со студенческих лет меня привлекала киноиндустрия. Триллеры, ужасы, мистика... И, наконец, я погрузился в это с головой. Хочу вывести кино на новый уровень, совместив его с объемными голограммами. Именно над этим сейчас и работаю. Только представьте: вы смотрите фильм и тут: «БАМ» ...на вас резко выпрыгивает приведение! Ха-ха-ха. Это будет прорыв.',
            audio : genry_tryman_5,
        },
        {
            id : 2,
            title : 'interrogations.interrogation52',
            text : 'Дело даже не в партнёрских отношениях. Мне прискорбно об этом говорить, но я разочаровался в своём брате, как в человеке. Однажды репутация парка для него оказалась превыше человеческой судьбы. Мы долго спорили, но переубедить его было невозможно. Поэтому я принял решение больше не иметь к этому никакого отношения',
            audio : genry_tryman_6,
        },
        {
            id : 3,
            title : 'interrogations.interrogation53',
            text : 'Она моя гордость и отрада в жизни. Моя малышка Сэмми. Ее мать умерла, когда ей не было и пяти лет. Поэтому я постарался дать ей всё, чтобы она ни в чем не нуждалась. Учеба в престижном университете, хорошая должность. Она буквально рождена управленцем, и я очень рад, что Саманта продолжает мое дело, в которое я вложил душу в своё время. Пусть я и отошёл от этих дел, но до сих с трепетом отношусь к парку.',
            audio : genry_tryman_4,
        },
        {
            id : 4,
            title : 'interrogations.interrogation54',
            text : 'Вы про эту мазню? Глупости. Детская забава. Это просто несерьёзно. Несколько лет назад она нашла на чердаке набор масляных красок матери и с тех пор ее голова забита этой ерундой. Саманте нужно сконцентрироваться на учебе и получении диплома, а не возить кисточками по холсту.',
            audio : genry_tryman_1,
        },
        {
            id : 5,
            title : 'interrogations.interrogation55',
            text : 'Разумеется. Только ленивый не говорит о проклятом парке. Знаете, что я вам скажу? Зная Виктора, не удивлюсь, если он собственноручно все это организовал, чтобы привлечь внимание к парку. Ему нужно шоу, эпатаж. Но если это действительно его затея, то явно что-то пошло не по плану…эх, Виктор, Виктор...что у тебя там происходит?',
            audio : genry_tryman_3,
        },
        {
            id : 6,
            title : 'interrogations.interrogation56',
            text : 'Если бы я действительно верил в мистику, то я бы без сомнения был уверен, что все происходящее связано с тем несчастным случаем на Чертовом колесе и погибшей девушкой, но этого просто быть не может. Кто бы это ни был - призрак или живой человек, он явно хочет насолить парку.',
            audio : genry_tryman_2,
        },
    ]
}

export default GENRY_TRYMAN;