import samanta_tryman_1 from '../../../audio/samanta_tryman_1.mp3';
import samanta_tryman_2 from '../../../audio/samanta_tryman_2.mp3';
import samanta_tryman_3 from '../../../audio/samanta_tryman_3.mp3';
import samanta_tryman_4 from '../../../audio/samanta_tryman_4.mp3';
import samanta_tryman_5 from '../../../audio/samanta_tryman_5.mp3';
import samanta_tryman_6 from '../../../audio/samanta_tryman_6.mp3';

import samanta_tryman_img from "../../../img/persons/samanta_tryman.png";
import samanta_tryman_color_img from "../../../img/persons/samanta_tryman_color.jpg";

const SAMANTA_TRYMAN = {
    id : 3,
    path : 'samanta_tryman',
    name: 'Саманта Труман',
    position: 'Администратор, дочь Генри Трумана',
    isCriminal : false,
    img : samanta_tryman_img,
    imgColor : samanta_tryman_color_img,
    interrogation: [
        {
            id : 1,
            title: 'interrogations.interrogation31',
            text: 'Мне некогда об этом думать. Слишком много работы в парке. По сути, я не принимала решения работать здесь. За меня ещё давно всё решил мой отец. Я просто стараюсь выполнять то, что от меня требуется.',
            audio: samanta_tryman_1,
        },
        {
            id : 2,
            title: 'interrogations.interrogation32',
            text: 'Моя страсть — это живопись. Когда я беру кисточки в руки, то забываю обо всем. Моя мама была великолепной художницей. Когда я рисую, то мне кажется, что она снова рядом. Мне ее очень не хватает. Она бы точно поддержала мое увлечение',
            audio: samanta_tryman_4,
        },
        {
            id : 3,
            title: 'interrogations.interrogation33',
            text: 'Он ничего даже слышать не хочет. Считает это детской глупостью и что этим не заработать на жизнь. Он во многом не понимает меня и слишком часто категоричен. Хоть он старается этого не показывать, но он очень скучает по парку. Может, поэтому так мечтает, чтобы я продолжала его дело здесь.',
            audio: samanta_tryman_6,
        },
        {
            id : 4,
            title: 'interrogations.interrogation34',
            text: 'Абсолютно нет. Дядя Виктор очень внимателен и добр ко мне. Иногда мне кажется, что отношения с ним у меня даже ближе, чем с родным отцом. Он даже разрешил оставаться мне по ночам в парке и рисовать. Только тссс.... Не хочу, чтобы об этом узнал отец',
            audio: samanta_tryman_3,
        },
        {
            id : 5,
            title: 'interrogations.interrogation35',
            text: 'Я безумно боюсь того, что происходит в парке. На той неделе я решила остаться здесь ночью и порисовать в тишине в спокойной обстановке. Как только я дошла до центральной площади парка, заметила странный зелёный туман. Потом раздался скрип, который пробрал все моё тело до самых костей и колесо запустилось! А затем я увидела девушку.... Она вся светилась, шла молча в сторону колеса и пела. Мамочки, как вспомню - мурашки по коже. Это она! Я точно знаю, что это она. Я видела в детстве у папы газеты, где писали про ту несчастную девушку, которая погибла на этом Чертовом колесе. Увидев это, я сразу убежала в сторону выхода. С тех пор я стараюсь уйти из парка до наступления темноты.',
            audio: samanta_tryman_2,
        },
        {
            id : 6,
            title: 'interrogations.interrogation36',
            text: 'Я не люблю говорить плохо о людях, но он наводит на меня страх. Вечно угрюмый и ворчливый. Я помню его ещё с детства, когда парк только открывался. Он всегда таким был. Как-то раз я слышала, как он ворчал наедине с собой и приговаривал, что «лучше бы этого парка никогда здесь не было. И что он сломал ему жизнь». Понятия не имею, о чем это он. Может снова выпил лишнего. Я стараюсь лишний раз с ним не пересекаться и не контактировать.',
            audio: samanta_tryman_5,
        },
    ],
}

export default SAMANTA_TRYMAN;