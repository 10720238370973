import React, {createContext, useContext, useReducer} from 'react';

import ITEMS from "../constants/game/items";
import * as ACTIONS from "../constants/actions";

import {pushIfNotExist} from "../helper/pushIfNotExist";
import {save, clear} from "../helper/save";
/** Название сущности, для удобства в каждом Контексте
 * будет одинаково называться */
const ENTITY = 'item';
/** Синонимы - чтоб не мучаться и заполнять поле сущность =) */
const entitySave = (data) => save(ENTITY, data);
const entityClear = (data) => clear(ENTITY, data);

const ItemStateContext = createContext();
const ItemDispatchContext = createContext();

function itemReducer(state, action) {
    let id;
    switch (action.type) {
        case ACTIONS.CLEAR_ITEM :
            return entityClear(init(defaultState));
        case ACTIONS.ADD_ITEM :
            id = action.payload.id;
            pushIfNotExist(id, ITEMS, state);
            state.sort((a, b) => a.id-b.id);
            return entitySave(state);
        case ACTIONS.SET_ITEM_CHECKED :
            id = action.payload.id;
            state.find(el => el.id === id).used = true;
            return entitySave(state);
        case ACTIONS.SET_FROM_STASH :
            let newState = action.payload.state
            return entitySave(newState);
        default:
            //console.log('По логике не должны сюда попадать - что то не так =)');
            return state;
    }
}

const defaultState = [];
const init = (state) => state;
const itemInitialState = JSON.parse(localStorage.getItem(ENTITY)) ?? init(defaultState);

function ItemProvider({children}) {
    const [item, itemDispatch] = useReducer(itemReducer, itemInitialState, init);
    return (
        <ItemStateContext.Provider value={item}>
            <ItemDispatchContext.Provider value={itemDispatch}>
                {children}
            </ItemDispatchContext.Provider>
        </ItemStateContext.Provider>
    )
}

function useItemState() {
    return useContext(ItemStateContext);
}

function useItemDispatch() {
    return useContext(ItemDispatchContext);
}

function useItem() {
    return [useItemState(), useItemDispatch()];
}

export {ItemProvider, useItemState, useItemDispatch, useItem}