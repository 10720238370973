import React, {createContext, useContext, useReducer} from 'react';

import * as ACTIONS from "../constants/actions";
import LOCATIONS from "../constants/game/locations";
import INSPECTION_QUESTION from "../constants/game/inspectionQuestion";

import {pushIfNotExist, pushIfNotExistByList} from "../helper/pushIfNotExist";
import {save, clear} from "../helper/save";
import {randomQuestion} from "../helper/inspectionQuestionHelper";
/** Название сущности, для удобства в каждом Контексте
 * будет одинаково называться */
const ENTITY = 'location';
/** Синонимы - чтоб не мучаться и заполнять поле сущность =) */
const entitySave = (data) => save(ENTITY, data);
const entityClear = (data) => clear(ENTITY, data);

const LocationStateContext = createContext();
const LocationDispatchContext = createContext();

function locationReducer(state, action) {
    let count, id, name, status, location;

    switch (action.type) {
        case ACTIONS.CLEAR_LOCATIONS :
            return entityClear(init(defaultState));
        case ACTIONS.SET_CHANGE_LOCATION_STATUS :
            status = action.payload.status;
            state.changeLocation = status;
            return entitySave(state);
        case ACTIONS.ADD_LOCATION :
            id = action.payload.id;
            pushIfNotExist(id, LOCATIONS, state.locations);
            return entitySave(state);
        case ACTIONS.ADD_LOCATION_BY_IDS :
            let locationIdsList = action.payload.locationIdsList;
            /** Тут нужно добавить проверку на наличие данной локации в текущем состоянии */
            pushIfNotExistByList(locationIdsList, LOCATIONS, state.locations);
            return entitySave(state);
        case ACTIONS.CHECK_FIELD :
            id = action.payload.id;
            name = action.payload.name;
            location = state.locations.find(el => el.id === 1*id);
            let field = location.fields.find(el => el.name === name);
            field.isInspected = true;
            location.isInspected = location.fields.every(el => el.isInspected === true);
            return entitySave(state);
        case ACTIONS.SET_INSPECTED :
            id = action.payload.id;
            state.locations.find(el => el.id === 1*id).onInspectedStatus = true;
            return entitySave(state);
        case ACTIONS.INSERT_INSPECTION_MAX :
            count = action.payload.count;
            state.inspection.max += count;
            return entitySave(state);
        case ACTIONS.INSERT_INSPECTION :
            count = action.payload.count;
            state.inspection.available += count;
            state.inspection.current += count;
            return entitySave(state);
        case ACTIONS.REDUCE_INSPECTION :
            count = action.payload.count;
            state.inspection.available -= count;
            return entitySave(state);
        case ACTIONS.SET_FROM_STASH :
            return entitySave(action.payload.state);
        case ACTIONS.INSPECTION_QUESTION_RANDOM_ORDER :
            {
                let {current, order, list} = state.inspectionQuestion;
                if(order.length === 0){
                    order = randomQuestion(list)
                }
                state.inspectionQuestion.order = order;
                state.inspectionQuestion.current = 0;
                return entitySave(state);
            }
        case ACTIONS.INSPECTION_QUESTION_CHANGE :
            state.inspectionQuestion.current = action.payload.ix;
            return entitySave(state);
        case ACTIONS.INSPECTION_QUESTION_SET_RESOLVED :
            {
                let next = action.payload.ix;
                let {current, order, list} = state.inspectionQuestion;
                console.log({current, next, order, list});
                order = order.filter(el => el !== current);
                list = list.map(el => {
                    if(el.id === current){
                        el.decided = true;
                    }
                    return el;
                });
                current = next;

                state.inspectionQuestion = {
                    current : current,
                    order : order,
                    list : list
                }
                return state;
            }
        default:
            //console.log('По логике не должны сюда попадать - что то не так =)');
            return state;
    }
}

const defaultState = {
    locations : [],
    inspection : {
        // Лимит по осмотрам
        max : 0,
        // Всего получено на текущий момент
        current : 0,
        // Доступно
        available : 0
    },
    inspectionQuestion : {
        list : INSPECTION_QUESTION,
        current : null,
        order : [],
    },
    changeLocation : false,
};
const init = (state) => state;
const locationInitialState = JSON.parse(localStorage.getItem(ENTITY)) ?? init(defaultState);

function LocationProvider({children}) {
    const [location, locationDispatch] = useReducer(locationReducer, locationInitialState, init);
    return (
        <LocationStateContext.Provider value={location}>
            <LocationDispatchContext.Provider value={locationDispatch}>
                {children}
            </LocationDispatchContext.Provider>
        </LocationStateContext.Provider>
    )
}

function useLocationState() {
    return useContext(LocationStateContext);
}

function useLocationDispatch() {
    return useContext(LocationDispatchContext);
}

function useLocation() {
    return [useLocationState(), useLocationDispatch()];
}

export {LocationProvider, useLocationState, useLocationDispatch, useLocation}
