import * as Colors from '../colors';
import {getCard} from "../../helper/getCard";
import {getItem} from "../../helper/getItem";
import {getClue, getClueByIds} from "../../helper/getClue";

const QUESTIONS =  [
    {
        id : 1,
        color : Colors.GRAY,
        answer : {type : 'number', answer : '2', params : {'maxLength': 1}},
        body : false,
        help : [
            {text : "helps.help11", availible : true},
            {text : "helps.help12", availible : false},
            {text : "helps.help13", availible : false},
        ],
        action : getCard(5),
        message : ['service.getCard', {card : 'S5'}],
        condition : null,
    },
    {
        id : 2,
        color : Colors.BLACK,
        answer : {type : 'number', answer : '45197', params : {'maxLength': 5}},
        body : false,
        help : [
            {text : "helps.help21", availible : true},
            {text : "helps.help22", availible : false},
            {text : "helps.help23", availible : false},
        ],
        action : getCard(6),
        message : ['service.getCard', {card : 'S6'}],
        condition : null,
    },
    {
        id : 3,
        color : Colors.WHITE,
        answer : {type : 'number', answer : '9', params : {'maxLength': 1}},
        body : false,
        help : [
            {text : "helps.help31", availible : true},
            {text : "helps.help32", availible : false},
            {text : "helps.help33", availible : false},
        ],
        action : getClue(1),
        message : ['service.getCard', {card : 'E1'}],
        condition : null,
    },
    {
        id : 4,
        color : Colors.BROWN,
        answer : {type : 'number', answer : '3421', params : {'maxLength': 4}},
        body : false,
        help : [
            {text : "helps.help41", availible : true},
            {text : "helps.help42", availible : false},
            {text : "helps.help43", availible : false},
        ],
        action : getItem(1),
        message : ['service.getCard', {card : 'i1'}],
        condition : null,
    },
    {
        id : 5,
        color : Colors.ORANGE,
        answer : {type : 'number', answer : '1995', params : {'maxLength': 4}},
        body : false,
        help : [
            {text : "helps.help51", availible : true},
            {text : "helps.help52", availible : false},
            {text : "helps.help53", availible : false},
        ],
        action : getClue(3),
        message : ['service.getCard', {card : 'E3'}],
        condition : null,
    },
    {
        id : 6,
        color : Colors.YELLOW,
        answer : {type : 'number', answer : '391', params : {'maxLength': 3}},
        body : false,
        help : [
            {text : "helps.help61", availible : true},
            {text : "helps.help62", availible : false},
            {text : "helps.help63", availible : false},
        ],
        action : getClue(2),
        message : ['service.getCard', {card : 'E2'}],
        condition : null,
    },
    {
        id : 7,
        color : Colors.DARK_GREEN,
        answer : {type : 'number', answer : '852', params : {'maxLength': 3}},
        body : false,
        help : [
            {text : "helps.help71", availible : true},
            {text : "helps.help72", availible : false},
            {text : "helps.help73", availible : false},
        ],
        action : getClue(5),
        message : ['service.getCard', {card : 'E5'}],
        condition : null,
    },
    {
        id : 8,
        color : Colors.GREEN,
        answer : {type : 'number', answer : '41352', params : {'maxLength': 5}},
        body : false,
        help : [
            {text : "helps.help81", availible : true},
            {text : "helps.help82", availible : false},
            {text : "helps.help83", availible : false},
        ],
        action : getItem(11),
        message : ['question.question1', {card : 'i11'}],
        condition : null,
    },
    {
        id : 9,
        color : Colors.KHAKI,
        answer : {type : 'number', answer : '5132', params : {'maxLength': 4}},
        body : 'AudioQuestion',
        help : [
            {text : "helps.help91", availible : true},
            {text : "helps.help92", availible : false},
            {text : "helps.help93", availible : false},
        ],
        action : getItem(5),
        message : ['service.getCard', {card : 'i5'}],
        condition : null,
    },
    {
        id : 10,
        color : Colors.LIGHT_GREEN,
        answer : {type : 'number', answer : '1568', params : {'maxLength': 4}},
        body : false,
        help : [
            {text : "helps.help101", availible : true},
            {text : "helps.help102", availible : false},
            {text : "helps.help103", availible : false},
        ],
        action : getItem(6),
        message : ['question.question10', {}],
        condition : {
            item : {
                used : [5],
            }
        },
    },
    {
        id : 11,
        color : Colors.BLUE,
        answer : {type : 'number', answer : '3574', params : {'maxLength': 4}},
        body : false,
        help : [
            {text : "helps.help111", availible : true},
            {text : "helps.help112", availible : false},
            {text : "helps.help113", availible : false},
        ],
        action : getCard(26),
        message : ['service.getCard', {card : 'S26'}],
        condition : null,
    },
    {
        id : 12,
        color : Colors.AQUA,
        answer : {type : 'number', answer : '7315', params : {'maxLength': 4}},
        body : false,
        help : [
            {text : "helps.help121", availible : true},
            {text : "helps.help122", availible : false},
            {text : "helps.help123", availible : false},
        ],
        action : getItem(7),
        message : ['question.question12', {}],
        condition : null,
    },
    {
        id : 13,
        color : Colors.DARK_BLUE,
        answer : {type : 'number', answer : '234615', params : {'maxLength': 6}},
        body : false,
        help : [
            {text : "helps.help131", availible : true},
            {text : "helps.help132", availible : false},
            {text : "helps.help133", availible : false},
        ],
        action : [],
        message : ['messages.worked', {}],
        condition : null,
    },
    {
        id : 14,
        color : Colors.LEMONY,
        answer : {type : 'number', answer : '167', params : {'maxLength': 3}},
        body : false,
        help : [
            {text : "helps.help141", availible : true},
            {text : "helps.help142", availible : false},
            {text : "helps.help143", availible : false},
        ],
        action : getClue(4),
        message : ['service.getCard', {card : 'E4'}],
        condition : null,
    },
    {
        id : 15,
        color : Colors.PINK,
        answer : {type : 'number', answer : '9', params : {'maxLength': 1}},
        body : false,
        help : [
            {text : "helps.help151", availible : true},
            {text : "helps.help152", availible : false},
            {text : "helps.help153", availible : false},
        ],
        action : getCard(44),
        message : ['service.getCard', {card : 'S39'}],
        condition : null,
    },
    {
        id : 16,
        color : Colors.RED,
        answer : {type : 'number', answer : '13', params : {'maxLength': 2}},
        body : false,
        help : [
            {text : "helps.help161", availible : true},
            {text : "helps.help162", availible : false},
            {text : "helps.help163", availible : false},
        ],
        action : [],
        message : ['question.question16', {}],
        condition : null,
    },
    {
        id : 17,
        color : Colors.BURGUNDY,
        answer : {type : 'number', answer : '6417', params : {'maxLength': 4}},
        body : false,
        help : [
            {text : "helps.help171", availible : true},
            {text : "helps.help172", availible : false},
            {text : "helps.help173", availible : false},
        ],
        action : getItem(9),
        message : ['service.getCard', {card : 'i9'}],
        condition : null,
    },
    {
        id : 18,
        color : Colors.PURPLE,
        answer : {type : 'number', answer : '2', params : {'maxLength': 1}},
        body : false,
        help : [
            {text : "helps.help181", availible : true},
            {text : "helps.help182", availible : false},
            {text : "helps.help183", availible : false},
        ],
        action : getCard(44),
        message : ['service.getCard', {card : 'S44'}],
        condition : null,
    },
    {
        id : 19,
        color : Colors.LILAC,
        answer : {type : 'text', answer : 'желтопузик', params : {'maxLength': 10}},
        body : false,
        help : [
            {text : "helps.help191", availible : true},
            {text : "helps.help192", availible : false},
            {text : "helps.help193", availible : false},
        ],
        action : getClue(6),
        message : ['service.getCard', {card : 'E6'}],
        condition : null,
    },
    {
        id : 20,
        color : Colors.TURQUOISE,
        answer : {type : 'number', answer : '1379', params : {'maxLength': 4}},
        body : false,
        help : [
            {text : "helps.help201", availible : true},
            {text : "helps.help202", availible : false},
            {text : "helps.help203", availible : false},
        ],
        action : getItem(10),
        message : ['service.getCard', {card : 'i10'}],
        condition: {
            item : {
                used : [8],
            }
        }
    },
    {
        id : 21,
        color : Colors.LIGHT_BLUE,
        answer : {type : 'number', answer : '591', params : {'maxLength': 3}},
        body : false,
        help : [
            {text : "helps.help211", availible : true},
            {text : "helps.help212", availible : false},
            {text : "helps.help213", availible : false},
        ],
        action : [],
        message : ['messages.tunnel', {}],
        condition : {
            item : {
                used : [10],
            }
        },
    },
    {
        id : 22,
        color : Colors.GOLD,
        answer : {type : 'number', answer : '3275', params : {'maxLength': 4}},
        body : false,
        help : [
            {text : "helps.help221", availible : true},
            {text : "helps.help222", availible : false},
            {text : "helps.help223", availible : false},
        ],
        action : getClueByIds([8,9,10]),
        message : ['service.getCards', {cards : 'E8, E9, E10'}],
        condition : null,
    },
];

export default QUESTIONS;