import * as Routes from './route';

export const NAV_DESC_NAME = 'desc';
export const NAV_ANSWERS_NAME = 'answer';
export const NAV_INSPECTION_NAME = 'inspection';
export const NAV_INTERROGATION_NAME = 'interrogation';
export const NAV_USAGE_NAME = 'usage';
export const NAV_SETTING_NAME = 'setting';
export const NAV_INSPECTION_QUESTION_NAME = 'setting';

export const NAV_DESC = {name : NAV_DESC_NAME, title : 'navigation.desc', path : Routes.DESC};
export const NAV_ANSWERS = {name : NAV_ANSWERS_NAME, title : 'navigation.answer', path : Routes.ANSWERS};
export const NAV_INSPECTION = {name : NAV_INSPECTION_NAME, title : 'navigation.inspection', path : Routes.INSPECTION};
export const NAV_INTERROGATION = {name : NAV_INTERROGATION_NAME, title : 'navigation.interrogation', path : Routes.INTERROGATION};
export const NAV_USAGE = {name : NAV_USAGE_NAME, title : 'navigation.usage', path : Routes.USAGE};
export const NAV_SETTING = {name : NAV_SETTING_NAME, title : 'navigation.setting', path : Routes.SETTING};
export const NAV_INSPECTION_QUESTION = {name : NAV_INSPECTION_QUESTION_NAME, title : 'navigation.inspectionQuestion', path : Routes.INSPECTION_QUESTION};
export const NAV_TEST = {name : '', title : 'ОТЛАДКА!', path : Routes.TEST};

const NAVIGATION = [
    NAV_INSPECTION,
    NAV_INTERROGATION,
    NAV_USAGE,
    NAV_ANSWERS,
    NAV_DESC,
    NAV_SETTING,
    //NAV_TEST,
];

export const DESC_NAVIGATION = [
    NAV_INSPECTION,
    NAV_INTERROGATION,
    NAV_USAGE,
    NAV_ANSWERS,
];

export default NAVIGATION;