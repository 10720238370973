import React, {Suspense, lazy} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
//import {HashRouter as Router, Route, Switch} from "react-router-dom";

import * as Routes from './constants/route';

/** Анимация загрузки (Пока ждём ответ от API) */
import Loading from "./components/Loading/Loading";

import {ActionProvider} from "./context/action";
import {GameProvider} from "./context/game";
import {LocationProvider} from "./context/location";
import {PersonProvider} from "./context/person";
import {CardProvider} from "./context/card";
import {QuestionProvider} from "./context/question";
import {ItemProvider} from "./context/item";
import {ClueProvider} from "./context/clue";
import {StatsProvider} from "./context/stats";
/** Менеджер очереди действий */
import ActionHelper from "./components/ActionHelper/ActionHelper";
/** Публична только 1 страница, для доступа к остальным нужно выставить стартовые настройки */
import PrivateRoute from "./PrivateRoute";
/** import componentLoader from "./componentLoader"; */
import Timer from "./components/Timer/Timer";
import LazyErrorBoundary from "./components/ErrorBoundary/LazyErrorBoundary";
/** Основные блоки */
const Start = lazy(() => import("./pages/Start"));
const Welcome = lazy(() => import("./pages/Welcome"));
const Desc = lazy(() => import("./pages/Desc"));
const Answers = lazy(() => import("./pages/Answers"));
const EnterAnswer = lazy(() => import("./pages/EnterAnswer"));
const Inspection = lazy(() => import("./pages/Inspection"));
const InspectionQuestion = lazy(() => import("./pages/InspectionQuestion"));
const Interrogation = lazy(() => import("./pages/Interrogation"));
const InterrogationPerson = lazy(() => import("./pages/InterrogationPerson"));
const Usage = lazy(() => import("./pages/Usage"));
const Setting = lazy(() => import("./pages/Setting"));
const SelectCriminal = lazy(() => import("./pages/SelectCriminal"));
const Confession = lazy(() => import("./pages/Confession"));
const Achievements = lazy(() => import("./pages/Achievements"));
/** 404 */
const NoMatchPage = lazy(() => import("./pages/404"));
/** Смена состояний */
const Waiting = lazy(() => import("./pages/Waiting"));

/** TETS */
const Test = lazy(() => import("./pages/Test"));

function App(props) {
    return (
        <ActionProvider>
        <GameProvider>
        <LocationProvider>
        <PersonProvider>
        <CardProvider>
        <QuestionProvider>
        <ItemProvider>
        <ClueProvider>
        <StatsProvider>
            <ActionHelper />
            <Router>
                <Timer />
                <LazyErrorBoundary>
                <Suspense fallback={<Loading loading={false}/>}>
                    <Switch>
                        <Route exact path={'/test/'} component={Test} />
                        <Route exact path={Routes.HOME} component={Start} />
                        <Route exact path={Routes.WAITING} component={Waiting} />
                        <PrivateRoute exact path={Routes.WELCOME} component={Welcome} />
                        <PrivateRoute exact path={Routes.DESC} component={Desc} />
                        <PrivateRoute exact path={Routes.ANSWERS} component={Answers} />
                        <PrivateRoute exact path={Routes.ENTER_ANSWER} component={EnterAnswer} />
                        <PrivateRoute exact path={Routes.INSPECTION} component={Inspection} />
                        <PrivateRoute exact path={Routes.INTERROGATION} component={Interrogation} />
                        <PrivateRoute exact path={Routes.INTERROGATION_PERSON} component={InterrogationPerson} />
                        <PrivateRoute exact path={Routes.USAGE} component={Usage} />
                        <PrivateRoute exact path={Routes.SETTING} component={Setting} />
                        <PrivateRoute exact path={Routes.SELECT_CRIMINAL} component={SelectCriminal} />
                        <PrivateRoute exact path={Routes.CONFESSION} component={Confession} />
                        <PrivateRoute exact path={Routes.ACHIEVEMENTS} component={Achievements} />
                        <PrivateRoute exact path={Routes.INSPECTION_QUESTION} component={InspectionQuestion} />
                        <Route component={NoMatchPage}/>
                    </Switch>
                </Suspense>
                </LazyErrorBoundary>
            </Router>
        </StatsProvider>
        </ClueProvider>
        </ItemProvider>
        </QuestionProvider>
        </CardProvider>
        </PersonProvider>
        </LocationProvider>
        </GameProvider>
        </ActionProvider>
    );
}

export default App;
//