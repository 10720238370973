import * as ACTIONS from "../constants/actions";
import * as DISPATCHERS from "../constants/dispatchers";

import {createAction} from './actionHelper';
import {NAV_INSPECTION_NAME, NAV_INTERROGATION_NAME} from "../constants/navigation";

export function changeLocation(location_id, person_id, inspection_count, interrogation_count, inspection_max_count = 6){
    let action_list = [];

    if(location_id !== null){
        action_list.push(createAction(DISPATCHERS.LOCATION, ACTIONS.ADD_LOCATION_BY_IDS, {locationIdsList : [location_id]}));
    }
    if(inspection_count !== null){
        action_list.push(createAction(DISPATCHERS.LOCATION, ACTIONS.INSERT_INSPECTION, {count : inspection_count}));
    }
    if(person_id !== null){
        action_list.push(createAction(DISPATCHERS.PERSON, ACTIONS.ADD_PERSON, {id : person_id}));
    }
    if(interrogation_count !== null){
        action_list.push(createAction(DISPATCHERS.PERSON, ACTIONS.INSERT_INTERROGATION, {count : interrogation_count}));
    }

    if(inspection_max_count !== null){
        action_list.push(createAction(DISPATCHERS.LOCATION, ACTIONS.INSERT_INSPECTION_MAX, {count : inspection_max_count}));
    }

    action_list.push(createAction(DISPATCHERS.GAME, ACTIONS.SET_NOTIFICATION, {notificationName : NAV_INSPECTION_NAME}));
    action_list.push(createAction(DISPATCHERS.GAME, ACTIONS.SET_NOTIFICATION, {notificationName : NAV_INTERROGATION_NAME}));

    return action_list;
}

export function changeLocationStatus(status){
    return [createAction(DISPATCHERS.LOCATION, ACTIONS.SET_CHANGE_LOCATION_STATUS, {status : status})];
}