import React, {createContext, useContext, useReducer} from 'react';

import * as ACTIONS from "../constants/actions";

import {save, clear} from "../helper/save";
/** Название сущности, для удобства в каждом Контексте
 * будет одинаково называться */
const ENTITY = 'stats';
/** Синонимы - чтоб не мучаться и заполнять поле сущность =) */
const entitySave = (data) => save(ENTITY, data);
const entityClear = (data) => clear(ENTITY, data);

const StatsStateContext = createContext();
const StatsDispatchContext = createContext();

function statsReducer(state, action) {
    switch (action.type) {
        case ACTIONS.CLEAR_STATS :
            return entityClear(init(defaultState));
        case ACTIONS.SET_BAD_QUESTION_ANSWER :
            state.alwaysRight = false;
            return entitySave(state)
        case ACTIONS.ADD_ANSWER_TIME:
            state.answerTimeList.push(new Date().getTime());
            return entitySave(state);
        case ACTIONS.INCREASE_HELP_COUNT:
            state.helpCount++;
            return entitySave(state);
        case ACTIONS.INCREASE_TIMER :
            state.timer++;
            return entitySave(state);
        case ACTIONS.ON_TIMER :
            state.timerStatus = true;
            return entitySave(state);
        case ACTIONS.OFF_TIMER :
            state.timerStatus = false;
            return entitySave(state);
        case ACTIONS.INCREASE_BAD_ITEM_USAGE :
            state.badItemUsedCount++;
            return entitySave(state);
        case ACTIONS.SET_FROM_STASH :
            let newState = action.payload.state
            return entitySave(newState);
        default:
            //console.log('По логике не должны сюда попадать - что то не так =)');
            return state;
    }
}

const defaultState = {
    answerTimeList : [],
    helpCount : 0,
    timer : 0,
    timerStatus : false,
    badItemUsedCount : 0,
    alwaysRight : true,
};
const init = (state) => state;
const statsInitialState = JSON.parse(localStorage.getItem(ENTITY)) ?? init(defaultState);

function StatsProvider({children}) {
    const [stats, statsDispatch] = useReducer(statsReducer, statsInitialState, init);
    return (
        <StatsStateContext.Provider value={stats}>
            <StatsDispatchContext.Provider value={statsDispatch}>
                {children}
            </StatsDispatchContext.Provider>
        </StatsStateContext.Provider>
    )
}

function useStatsState() {
    return useContext(StatsStateContext);
}

function useStatsDispatch() {
    return useContext(StatsDispatchContext);
}

function useStats() {
    return [useStatsState(), useStatsDispatch()];
}

export {StatsProvider, useStatsState, useStatsDispatch, useStats}