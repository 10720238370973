import victor_tryman_1 from '../../../audio/victor_tryman_1.mp3';
import victor_tryman_2 from '../../../audio/victor_tryman_2.mp3';
import victor_tryman_3 from '../../../audio/victor_tryman_3.mp3';
import victor_tryman_4 from '../../../audio/victor_tryman_4.mp3';
import victor_tryman_5 from '../../../audio/victor_tryman_5.mp3';
import victor_tryman_6 from '../../../audio/victor_tryman_6.mp3';

import victor_tryman_img from "../../../img/persons/victor_tryman.png";
import victor_tryman_color_img from "../../../img/persons/victor_tryman_color.jpg";

const VICTOR_TRYMAN = {
    id : 2,
    path : 'victor_tryman',
    name : 'Виктор Труман',
    position : 'Владелец парка аттракционов',
    isCriminal : false,
    img : victor_tryman_img,
    imgColor : victor_tryman_color_img,
    interrogation : [
        {
            id : 1,
            title : 'interrogations.interrogation21',
            text : 'Был. Парк аттракционов братьев Труманов......Этот парк мы открывали вместе с моим братом Генри. Но наши пути разошлись несколько лет назад, и он вышел из дела. Поэтому я являюсь единственным владельцем данного парка аттракционов.',
            audio : victor_tryman_1,
        },
        {
            id : 2,
            title : 'interrogations.interrogation22',
            text : 'Примерно две недели назад. Первым очевидцем стал наш охранник, когда дежурил в ночную смену. На утро в панике он пытался несвязно объяснить что-то про зелёный туман и светящийся силуэт девушки в верхней кабине Чертового колеса. Тогда я не мог ещё воспринять его слова всерьез и списал все на галлюцинации от алкогольного опьянения. Грозился даже уволить его. Но после просмотра записей с видеокамер, я не нашёл слов... Все это было чистой правдой и выглядело очень жутко. С тех пор это повторяется каждую ночь.',
            audio : victor_tryman_5,
        },
        {
            id : 3,
            title : 'interrogations.interrogation23',
            text : 'Если не считать конфликта с моим братцем, я не припомню врагов. Я стараюсь всегда быть отзывчивым и внимательным к людям. Всегда рад каждому посетителю и сотруднику в моем парке.',
            audio : victor_tryman_4,
        },
        {
            id : 4,
            title : 'interrogations.interrogation24',
            text : 'Это мое личное дело, и оно вас не касается. Скажем так: я не нашёл поддержки в нем, когда так нуждался в этом. Он до сих пор считает меня неправым, но я не мог поступить иначе. Давайте вернёмся к обсуждению парка? ',
            audio : victor_tryman_6,
        },
        {
            id : 5,
            title : 'interrogations.interrogation25',
            text : 'Я затрудняюсь ответить на этот вопрос. До недавнего момента я считал всё это чушью собачьей, пока не увидел все собственными глазами. Волей-неволей начнешь верить. Но вопрос не в том, верю ли я в это. В это верят посетители парка и теперь с ужасом обходят его стороной. Ходят слухи, что парк проклят. Что бы это ни было - оно пугает людей. Без посетителей парк долго не протянет и закроется.',
            audio : victor_tryman_2,
        },
        {
            id : 6,
            title : 'interrogations.interrogation26',
            text : 'Полу? Абсолютно. Он, конечно, имеет скверный характер и не прочь приложиться к бутылке иногда, но при этом работу выполняет свою отменно. Не представляю этот парк без него.',
            audio : victor_tryman_3,
        },
    ],
};

export default VICTOR_TRYMAN;