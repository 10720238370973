import React from "react";

import {useAction} from "../../context/action";

import {useGameDispatch} from "../../context/game";
import {usePersonDispatch} from "../../context/person";
import {useLocationDispatch} from "../../context/location";
import {useCardDispatch} from "../../context/card";
import {useQuestionDispatch} from "../../context/question";
import {useItemDispatch} from "../../context/item";
import {useClueDispatch} from "../../context/clue";
import {useStatsDispatch} from "../../context/stats";

import * as DISPATCHERS from "../../constants/dispatchers";
import * as ACTIONS from "../../constants/actions";

/** Будем обрабатывать очереди событий
 *  Получаем массив действий
 *  Выполняем действия
 */
export default function ActionHelper() {
    /** Подписываемся на события */
    const [actions, actionReducer] = useAction();
    /** Подключаем все возможные хранилища */
    const gameReducer = useGameDispatch();
    const personReducer = usePersonDispatch();
    const locationReducer = useLocationDispatch();
    const cardReducer = useCardDispatch();
    const questionReducer = useQuestionDispatch();
    const itemReducer = useItemDispatch();
    const clueReducer = useClueDispatch();
    const statsReducer = useStatsDispatch();
    //console.log(actions);
    for(let i = 0; i < actions.length; i++){
        let action = actions[i];
        //console.log(action);
        switch (action.dispatcher){
            case DISPATCHERS.LOCATION:
                locationReducer({type : action.action, payload : action.payload});
                break;
            case DISPATCHERS.PERSON:
                personReducer({type : action.action, payload : action.payload});
                break;
            case DISPATCHERS.GAME:
                gameReducer({type : action.action, payload : action.payload});
                break;
            case DISPATCHERS.CARD :
                cardReducer({type : action.action, payload : action.payload});
                break
            case DISPATCHERS.QUESTION :
                questionReducer({type : action.action, payload : action.payload});
                break
            case DISPATCHERS.ITEM :
                itemReducer({type : action.action, payload : action.payload});
                break
            case DISPATCHERS.CLUE :
                clueReducer({type : action.action, payload : action.payload});
                break
            case DISPATCHERS.STATS :
                statsReducer({type : action.action, payload : action.payload});
                break
            default :
                //console.log('Фиаско!');
        }
    }

    if(actions.length > 0){
        actionReducer({type : ACTIONS.CLEAR_ACTIONS});
    }

    return <></>
}