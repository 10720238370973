import React, {useState} from "react";
import {useStats} from "../../context/stats";

import * as ACTIONS from "../../constants/actions";

const SECOND = 1000;
//const SECOND = 10;
const MINUTE = SECOND * 60;

export default function Timer(){
    const [stats, statsReducer] = useStats();
    const [interval, setItervalState] = useState(null);

    const timerStatus = stats.timerStatus;

    /** Если интервал еще не был создан - создаем */
    if(timerStatus && interval === null){
        /** Каждую миннуту будем увеличивать счётчкик */
        let link = setInterval(() => statsReducer({
            type : ACTIONS.INCREASE_TIMER,
            payload : {}
        }), MINUTE);
        setItervalState(link);
    }
    /** Если статус изменился на Выключен - проверяем - если есть интервал - отключаем */
    if(!timerStatus && interval !== null){
        clearInterval(interval);
        setItervalState(null);
    }

    return <></>
}