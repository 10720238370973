export function actionMessage(location_id, person_id, inspection_count, interrogation_count){
    let action_list = {
        'get' : [],
        'add' : [],
    };
    //Возьмите
    if(location_id !== null){
        action_list.get.push(['service.getCardLocation', {card : location_id}]);
    }
    if(person_id !== null){
        action_list.get.push(['service.getCardPerson', {card : person_id}]);
    }
    //Доступно
    if(inspection_count !== null){
        action_list.add.push(['service.getInspectionToken', {count : inspection_count}]);
    }
    if(interrogation_count !== null){
        action_list.add.push(['service.getInterrogationToken', {count : interrogation_count}]);
    }
    return action_list;
}