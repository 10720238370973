import React, {createContext, useContext, useReducer} from 'react';

import QUESTIONS from "../constants/game/questions";
import * as ACTIONS from "../constants/actions";

import {save, clear} from "../helper/save";
/** Название сущности, для удобства в каждом Контексте
 * будет одинаково называться */
const ENTITY = 'question';
/** Синонимы - чтоб не мучаться и заполнять поле сущность =) */
const entitySave = (data) => save(ENTITY, data);
const entityClear = (data) => clear(ENTITY, data);

const QuestionStateContext = createContext();
const QuestionDispatchContext = createContext();

function questionReducer(state, action) {
    let question_id, help_id, help;
    switch (action.type) {
        case ACTIONS.CLEAR_QUESTION :
            return entityClear(init(defaultState));

        /** Проверяем - добавлен ли вопрос
         *  Если не добавлен - добавляем
         *  Если добавлен - выставляем связь с карточкой
         *  */
        case ACTIONS.CHECK_QUESTION :
            let card = action.payload.card;
            /** Если карточка бесцветная - нет смысла в проверке */
            if(card.color === null){
                return state;
            }

            let {id, color} = card;

            /** Ищем вопросы по цвету */
            QUESTIONS.filter(el => el.color.id === color.id)
                .sort((a,b) => a.id > b.id)
                .map(function(el){
                    let question;
                    if((question = state.find(stateEl => stateEl.id === el.id)) === undefined){
                        /** Проверяем доступность вопроса - вопрос доступен - если решен предыдущий */
                        let availible = false;
                        /** Первый элемент всегда доступен */
                        if(el.id === 1){
                            availible = true;
                        } else {
                            /** Ищем предыдущий вопрос */
                            let find = state.find(findEl => findEl.id === el.id - 1);
                            /** Если такой вопрос существует - проверяем - решен ли он
                             *  И если нет требований по доступности */
                            if(find && find.decided && el.condition === null){
                                availible = true;
                            }
                        }

                        state.push({
                            ...el,
                            linkedCards : [id],
                            decided : false,
                            availible : availible,
                            'new' : availible,
                        });
                    } else {
                        /** Если вопрос уже доступен - проверяем есть ли связь с проверяемой карточкой */
                        if(question.linkedCards.indexOf(id) === -1){
                            question.linkedCards.push(id);
                        }
                    }
                    /** Добавил из за исключения сборщика */
                    return state;
                });
            return entitySave(state);
        case ACTIONS.SET_HELP_SHOW :
            question_id = action.payload.question_id;
            help_id = action.payload.help_id;
            help = state.find(el => el.id === question_id)?.help;
            if(!help){
                return state;
            }
            help[help_id].show = true;
            if((help_id+1) < help.length){
                help[help_id+1].availible = true;
            }
            return entitySave(state);
        case ACTIONS.SET_QUESTION_STATUS :
            question_id = action.payload.question_id;
            let question = state.find(el => el.id === question_id);
            question.decided = true;
            /** Ищем следующий вопрос */
            /** TODO: Ненадежная позиция, в будущем возможна разница в id вопроса больше 1 */
            let find = state.find(el => el.id === question.id + 1);
            /** Если есть следующий вопрос и к нему нет дополнительных проверок -
             *  - открываем к нему доступ */
            if(find && question.decided && find.condition === null){
                find.availible = true;
                /** Указываем что это новый вопрос (Нужно для Уведомлений) */
                find.new = true;
            }
            return entitySave(state);
        case ACTIONS.SET_QUESTION_AVAILIBLE :
            question_id = action.payload.question_id;
            state.find(el => el.id === question_id).availible = true;
            return entitySave(state);
        case ACTIONS.SET_QUESTION_VISITED :
            state.filter(el => el.availible && el.new).map(el => {
                el.new = false;
                return el;
            });
            return entitySave(state);
        case ACTIONS.SET_FROM_STASH :
            let newState = action.payload.state
            return entitySave(newState);
        default:
            //console.log('По логике не должны сюда попадать - что то не так =)');
            return state;
    }
}

const defaultState = [];
const init = (state) => state;
const questionInitialState = JSON.parse(localStorage.getItem(ENTITY)) ?? init(defaultState);

function QuestionProvider({children}) {
    const [question, questionDispatch] = useReducer(questionReducer, questionInitialState, init);
    return (
        <QuestionStateContext.Provider value={question}>
            <QuestionDispatchContext.Provider value={questionDispatch}>
                {children}
            </QuestionDispatchContext.Provider>
        </QuestionStateContext.Provider>
    )
}

function useQuestionState() {
    return useContext(QuestionStateContext);
}

function useQuestionDispatch() {
    return useContext(QuestionDispatchContext);
}

function useQuestion() {
    return [useQuestionState(), useQuestionDispatch()];
}

export {QuestionProvider, useQuestionState, useQuestionDispatch, useQuestion}
