/** Сохраняем значение в LocalStorage */
export function save(entity, val){
    localStorage.setItem(entity, JSON.stringify(val));
    return JSON.parse(JSON.stringify(val));
}
/** Удаляем значение из LocalStorage */
export function clear(entity, val){
    //console.log(entity, val);
    localStorage.removeItem(entity);
    return JSON.parse(JSON.stringify(val));
}
/** Иногда не нужно писать в LocalStorage */
export function createObject(val){
    return JSON.parse(JSON.stringify(val));
}