import POL_RYAT from "./persons/pol_rait";
import VICTOR_TRYMAN from "./persons/victor_tryman";
import SAMANTA_TRYMAN from "./persons/samanta_tryman";
import ALICE_FINN from "./persons/alice_finn";
import GENRY_TRYMAN from "./persons/genry_tryman";

const PERSONS = [
    POL_RYAT,
    VICTOR_TRYMAN,
    SAMANTA_TRYMAN,
    ALICE_FINN,
    GENRY_TRYMAN,
];

export default PERSONS;