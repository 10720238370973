import * as Colors from "../colors";

const CARDS =  [
    {id: 1, color : Colors.BLACK},
    {id: 2, color : null},
    {id: 3, color : Colors.GRAY},
    {id: 4, color : Colors.BLACK},
    {id: 5, color : Colors.BLACK},
    {id: 6, color : Colors.WHITE},
    {id: 7, color : Colors.BROWN},
    {id: 8, color : Colors.ORANGE},
    {id: 9, color : Colors.ORANGE},
    {id: 10, color : Colors.ORANGE},
    {id: 11, color : Colors.BROWN},
    {id: 12, color : Colors.YELLOW},
    {id: 13, color : Colors.LEMONY},
    {id: 14, color : Colors.DARK_GREEN},
    {id: 15, color : Colors.LEMONY},
    {id: 16, color : Colors.LEMONY},
    {id: 17, color : Colors.GREEN},
    {id: 18, color : Colors.LIGHT_GREEN},
    {id: 19, color : null},
    {id: 20, color : Colors.KHAKI},
    {id: 21, color : Colors.KHAKI},
    {id: 22, color : Colors.LIGHT_GREEN},
    {id: 23, color : Colors.DARK_BLUE},
    {id: 24, color : Colors.DARK_BLUE},
    {id: 25, color : Colors.BLUE},
    {id: 26, color : Colors.DARK_BLUE},
    {id: 27, color : Colors.LIGHT_BLUE},
    {id: 28, color : Colors.AQUA},
    {id: 29, color : Colors.LIGHT_BLUE},
    {id: 30, color : Colors.LIGHT_BLUE},
    {id: 31, color : Colors.TURQUOISE},
    {id: 32, color : null},
    {id: 33, color : Colors.TURQUOISE},
    {id: 34, color : Colors.RED},
    {id: 35, color : Colors.RED},
    {id: 36, color : Colors.PINK},
    {id: 37, color : Colors.PINK},
    {id: 38, color : Colors.RED},
    {id: 39, color : Colors.RED},
    {id: 40, color : Colors.BURGUNDY},
    {id: 41, color : null},
    {id: 42, color : Colors.BURGUNDY},
    {id: 43, color : Colors.PURPLE},
    {id: 44, color : Colors.LILAC},
    {id: 45, color : Colors.GOLD},
    {id: 46, color : Colors.GOLD},
    {id: 47, color : Colors.GOLD},
];

export default CARDS