import React, {createContext, useContext, useReducer} from 'react';

import PERSONS from "../constants/game/persons";
import * as ACTIONS from "../constants/actions";

import {pushIfNotExist} from "../helper/pushIfNotExist";
import {save, clear} from "../helper/save";
/** Название сущности, для удобства в каждом Контексте
 * будет одинаково называться */
const ENTITY = 'person';
/** Синонимы - чтоб не мучаться и заполнять поле сущность =) */
const entitySave = (data) => save(ENTITY, data);
const entityClear = (data) => clear(ENTITY, data);

const PersonStateContext = createContext();
const PersonDispatchContext = createContext();

function personReducer(state, action) {
    let count, id, interrogation_id;
    switch (action.type) {
        case ACTIONS.CLEAR_PERSON :
            return entityClear(init(defaultState));
        case ACTIONS.ADD_PERSON :
            id = action.payload.id;
            pushIfNotExist(id, PERSONS, state.persons);
            return entitySave(state);
        case ACTIONS.SET_INTERROGATION_CHECKED :
            id = action.payload.id;
            interrogation_id = action.payload.interrogation_id;
            state.persons.find(el => el.id === id).interrogation.find(el => el.id === interrogation_id).isChecked = true;
            return entitySave(state);
        case ACTIONS.SET_INTERROGATION_LISTENED :
            id = action.payload.id;
            interrogation_id = action.payload.interrogation_id;
            state.persons.find(el => el.id === id).interrogation.find(el => el.id === interrogation_id).isListened = true;
            return entitySave(state);
        case ACTIONS.INSERT_INTERROGATION :
            count = action.payload.count;
            state.interrogation += count;
            return entitySave(state);
        case ACTIONS.REDUCE_INTERROGATION :
            count = action.payload.count;
            state.interrogation -= count;
            return entitySave(state);
        case ACTIONS.SET_FROM_STASH :
            let newState = action.payload.state
            return entitySave(newState);
        default:
            //console.log('По логике не должны сюда попадать - что то не так =)');
            return state;
    }
}

const defaultState = {
    persons : [],
    interrogation : 0,
};
const init = (state) => state;
const personInitialState = JSON.parse(localStorage.getItem(ENTITY)) ?? init(defaultState);

function PersonProvider({children}) {
    const [person, personDispatch] = useReducer(personReducer, personInitialState, init);
    return (
        <PersonStateContext.Provider value={person}>
            <PersonDispatchContext.Provider value={personDispatch}>
                {children}
            </PersonDispatchContext.Provider>
        </PersonStateContext.Provider>
    )
}

function usePersonState() {
    return useContext(PersonStateContext);
}

function usePersonDispatch() {
    return useContext(PersonDispatchContext);
}

function usePerson() {
    return [usePersonState(), usePersonDispatch()];
}

export {PersonProvider, usePersonState, usePersonDispatch, usePerson}
