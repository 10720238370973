import React, {createContext, useContext, useReducer} from 'react';

import * as ACTIONS from "../constants/actions";
import * as GAME_STATE from "../constants/gameState";

import {save, clear} from "../helper/save";
/** Название сущности, для удобства в каждом Контексте
 * будет одинаково называться */
const ENTITY = 'game';
/** Синонимы - чтоб не мучаться и заполнять поле сущность =) */
const entitySave = (data) => save(ENTITY, data);
const entityClear = (data) => clear(ENTITY, data);

const GameStateContext = createContext();
const GameDispatchContext = createContext();

const DEFAULT_GAME_ID = null;
const DEFAULT_DIFFICULT = null;

function gameReducer(state, action) {
  let difficult, gameId, notificationName;

  switch (action.type) {
    case ACTIONS.CLEAR_GAME:
      return entityClear(init(defaultState()));
    case ACTIONS.SET_GAME:
      difficult = action.payload.difficult;
      gameId = action.payload.gameId;
      state.difficult = difficult;
      state.gameId = gameId;
      state.gameState = GAME_STATE.INIT;
      return entitySave(state);
    case ACTIONS.SET_GAME_STATE:
      state.gameState = action.payload.gameState;
      return entitySave(state);
    case ACTIONS.SET_DIFFICULT:
      difficult = action.payload.difficult;
      state.difficult = difficult;
      return entitySave(state);
    case ACTIONS.SET_NOTIFICATION :
      notificationName = action.payload.notificationName;
      if(!state.notification[notificationName]){
        state.notification[notificationName] = {};
      }
      return entitySave(state);
    case ACTIONS.CLEAR_NOTIFICATION :
      notificationName = action.payload.notificationName;
      if(state.notification[notificationName]){
        delete state.notification[notificationName];
      }
      return entitySave(state);
    case ACTIONS.SET_SHOW_LAST_QUESTION :
      state.gameState = GAME_STATE.FINAL;
      state.showLastQuestion = true;
      return entitySave(state);
    case ACTIONS.SET_FROM_STASH :
      let newState = action.payload.state
      return entitySave(newState);
    default:
      //console.log('Если попали сюда - значит что то не так =)');
      return state;
  }
}

function defaultState(){
  return {
    difficult : DEFAULT_DIFFICULT,
    gameId : DEFAULT_GAME_ID,
    gameState : GAME_STATE.NOT_START,
    notification : {},
    showLastQuestion : false,
  }
}

const init = (state) => state;
const gameInitialState = JSON.parse(localStorage.getItem(ENTITY)) ?? init(defaultState());

function GameProvider({children}) {
  const [game, gameDispatch] = useReducer(gameReducer, gameInitialState, init);
  return (
    <GameStateContext.Provider value={game}>
      <GameDispatchContext.Provider value={gameDispatch}>
        {children}
      </GameDispatchContext.Provider>
    </GameStateContext.Provider>
  )
}

function useGameState() {
  return useContext(GameStateContext);
}

function useGameDispatch() {
  return useContext(GameDispatchContext);
}

function useGame() {
  return [useGameState(), useGameDispatch()];
}

export {GameProvider, useGameState, useGameDispatch, useGame}
