import * as Colors from "../colors";

export const ITEMS =  [
    {
        id : 1,
        name : 'items.item1',
        colour: null,
        canUse : true,
    },
    {
        id : 2,
        name : 'items.item2',
        colour: null,
        canUse : true,
    },
    {
        id : 3,
        name : 'items.item3',
        colour: null,
        canUse : true,
    },
    {
        id : 4,
        name : 'items.item4',
        colour: Colors.DARK_BLUE,
        canUse : true,
    },
    {
        id : 5,
        name : 'items.item5',
        colour: null,
        canUse : true,
    },
    {
        id : 6,
        name : 'items.item6',
        colour: Colors.AQUA,
        canUse : false,
    },
    {
        id : 7,
        name : 'items.item7',
        colour: Colors.LEMONY,
        canUse : false,
    },
    {
        id : 8,
        name : 'items.item8',
        colour: Colors.TURQUOISE,
        canUse : true,
    },
    {
        id : 9,
        name : 'items.item9',
        colour: null,
        canUse : true,
    },
    {
        id : 10,
        name : 'items.item10',
        colour: Colors.BLUE,
        canUse : true,
    },
    {
        id : 11,
        name : 'items.item11',
        colour: Colors.PURPLE,
        canUse : false,
    },
];

export default ITEMS;