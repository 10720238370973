import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useGameState} from "./context/game";

import RIGHT from "./constants/right";

function PrivateRoute({ component: Component, ...rest}) {
    const path = rest.path;
    const game = useGameState();
    const gameState = game.gameState;
    const right = RIGHT[path];
    /** TODO: Тут надо проверить что правило существует */
    let haveAccess = right.access.includes(gameState);
    return (
        <Route
            {...rest}
            render={props => haveAccess ?
                <Component {...props} />
                :
                <Redirect to={right.defaultRoute}/>
            }
        />
    );
}

export default PrivateRoute;