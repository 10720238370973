import React, {createContext, useContext, useReducer} from 'react';

import CARDS from "../constants/game/cards";
import * as ACTIONS from "../constants/actions";

import {pushIfNotExist} from "../helper/pushIfNotExist";
import {save, clear} from "../helper/save";
/** Название сущности, для удобства в каждом Контексте
 * будет одинаково называться */
const ENTITY = 'card';
/** Синонимы - чтоб не мучаться и заполнять поле сущность =) */
const entitySave = (data) => save(ENTITY, data);
const entityClear = (data) => clear(ENTITY, data);

const CardStateContext = createContext();
const CardDispatchContext = createContext();

function cardReducer(state, action) {
    switch (action.type) {
        case ACTIONS.CLEAR_CARD :
            return entityClear(init(defaultState));
        case ACTIONS.ADD_CARD :
            let id = action.payload.id;
            pushIfNotExist(id, CARDS, state);
            return entitySave(state);
        case ACTIONS.SET_FROM_STASH :
            let newState = action.payload.state
            return entitySave(newState);
        default:
            return state;
    }
}

const defaultState = [];
const init = (state) => state;
const cardInitialState = JSON.parse(localStorage.getItem(ENTITY)) ?? init(defaultState);

function CardProvider({children}) {
    const [card, cardDispatch] = useReducer(cardReducer, cardInitialState, init);
    return (
        <CardStateContext.Provider value={card}>
            <CardDispatchContext.Provider value={cardDispatch}>
                {children}
            </CardDispatchContext.Provider>
        </CardStateContext.Provider>
    )
}

function useCardState() {
    return useContext(CardStateContext);
}

function useCardDispatch() {
    return useContext(CardDispatchContext);
}

function useCard() {
    return [useCardState(), useCardDispatch()];
}

export {CardProvider, useCardState, useCardDispatch, useCard}