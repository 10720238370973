export function randomQuestion(list){
    return list.map(el => el.id).sort((a, b) => 0.5 - Math.random());
}

export function getPrevInspectionQuestion(current, list){
    return current - 1 >= 0 ? current - 1 : list.length - 1;
}

export function getNextInspectionQuestion(current, list){
    return current + 1 < list.length ? current + 1 : 0;
}