const INSPECTION_QUESTION = [
    {
        "id": 1,
        "question": "inspectionQuestion.inspectionQuestion1",
        "answer": "inspectionQuestion.inspectionAnswer1"
    },
    {
        "id": 2,
        "question": "inspectionQuestion.inspectionQuestion2",
        "answer": "inspectionQuestion.inspectionAnswer2"
    },
    {
        "id": 3,
        "question": "inspectionQuestion.inspectionQuestion3",
        "answer": "inspectionQuestion.inspectionAnswer3"
    },
    {
        "id": 4,
        "question": "inspectionQuestion.inspectionQuestion4",
        "answer": "inspectionQuestion.inspectionAnswer4"
    },
    {
        "id": 5,
        "question": "inspectionQuestion.inspectionQuestion5",
        "answer": "inspectionQuestion.inspectionAnswer5"
    },
    {
        "id": 6,
        "question": "inspectionQuestion.inspectionQuestion6",
        "answer": "inspectionQuestion.inspectionAnswer6"
    },
    {
        "id": 7,
        "question": "inspectionQuestion.inspectionQuestion7",
        "answer": "inspectionQuestion.inspectionAnswer7"
    },
    {
        "id": 8,
        "question": "inspectionQuestion.inspectionQuestion8",
        "answer": "inspectionQuestion.inspectionAnswer8"
    },
    {
        "id": 9,
        "question": "inspectionQuestion.inspectionQuestion9",
        "answer": "inspectionQuestion.inspectionAnswer9"
    },
    {
        "id": 10,
        "question": "inspectionQuestion.inspectionQuestion10",
        "answer": "inspectionQuestion.inspectionAnswer10"
    },
    {
        "id": 11,
        "question": "inspectionQuestion.inspectionQuestion11",
        "answer": "inspectionQuestion.inspectionAnswer11"
    },
    {
        "id": 12,
        "question": "inspectionQuestion.inspectionQuestion12",
        "answer": "inspectionQuestion.inspectionAnswer12"
    },
    {
        "id": 13,
        "question": "inspectionQuestion.inspectionQuestion13",
        "answer": "inspectionQuestion.inspectionAnswer13"
    },
    {
        "id": 14,
        "question": "inspectionQuestion.inspectionQuestion14",
        "answer": "inspectionQuestion.inspectionAnswer14"
    },
    {
        "id": 15,
        "question": "inspectionQuestion.inspectionQuestion15",
        "answer": "inspectionQuestion.inspectionAnswer15"
    },
    {
        "id": 16,
        "question": "inspectionQuestion.inspectionQuestion16",
        "answer": "inspectionQuestion.inspectionAnswer16"
    },
    {
        "id": 17,
        "question": "inspectionQuestion.inspectionQuestion17",
        "answer": "inspectionQuestion.inspectionAnswer17"
    },
    {
        "id": 18,
        "question": "inspectionQuestion.inspectionQuestion18",
        "answer": "inspectionQuestion.inspectionAnswer18"
    },
    {
        "id": 19,
        "question": "inspectionQuestion.inspectionQuestion19",
        "answer": "inspectionQuestion.inspectionAnswer19"
    },
    {
        "id": 20,
        "question": "inspectionQuestion.inspectionQuestion20",
        "answer": "inspectionQuestion.inspectionAnswer20"
    },
    {
        "id": 21,
        "question": "inspectionQuestion.inspectionQuestion21",
        "answer": "inspectionQuestion.inspectionAnswer21"
    },
    {
        "id": 22,
        "question": "inspectionQuestion.inspectionQuestion22",
        "answer": "inspectionQuestion.inspectionAnswer22"
    },
    {
        "id": 23,
        "question": "inspectionQuestion.inspectionQuestion23",
        "answer": "inspectionQuestion.inspectionAnswer23"
    },
    {
        "id": 24,
        "question": "inspectionQuestion.inspectionQuestion24",
        "answer": "inspectionQuestion.inspectionAnswer24"
    },
    {
        "id": 25,
        "question": "inspectionQuestion.inspectionQuestion25",
        "answer": "inspectionQuestion.inspectionAnswer25"
    },
    {
        "id": 26,
        "question": "inspectionQuestion.inspectionQuestion26",
        "answer": "inspectionQuestion.inspectionAnswer26"
    },
    {
        "id": 27,
        "question": "inspectionQuestion.inspectionQuestion27",
        "answer": "inspectionQuestion.inspectionAnswer27"
    },
    {
        "id": 28,
        "question": "inspectionQuestion.inspectionQuestion28",
        "answer": "inspectionQuestion.inspectionAnswer28"
    },
    {
        "id": 29,
        "question": "inspectionQuestion.inspectionQuestion29",
        "answer": "inspectionQuestion.inspectionAnswer29"
    },
    {
        "id": 30,
        "question": "inspectionQuestion.inspectionQuestion30",
        "answer": "inspectionQuestion.inspectionAnswer30"
    },
    {
        "id": 31,
        "question": "inspectionQuestion.inspectionQuestion31",
        "answer": "inspectionQuestion.inspectionAnswer31"
    },
    {
        "id": 32,
        "question": "inspectionQuestion.inspectionQuestion32",
        "answer": "inspectionQuestion.inspectionAnswer32"
    },
    {
        "id": 33,
        "question": "inspectionQuestion.inspectionQuestion33",
        "answer": "inspectionQuestion.inspectionAnswer33"
    },
    {
        "id": 34,
        "question": "inspectionQuestion.inspectionQuestion34",
        "answer": "inspectionQuestion.inspectionAnswer34"
    },
    {
        "id": 35,
        "question": "inspectionQuestion.inspectionQuestion35",
        "answer": "inspectionQuestion.inspectionAnswer35"
    },
    {
        "id": 36,
        "question": "inspectionQuestion.inspectionQuestion36",
        "answer": "inspectionQuestion.inspectionAnswer36"
    },
    {
        "id": 37,
        "question": "inspectionQuestion.inspectionQuestion37",
        "answer": "inspectionQuestion.inspectionAnswer37"
    },
    {
        "id": 38,
        "question": "inspectionQuestion.inspectionQuestion38",
        "answer": "inspectionQuestion.inspectionAnswer38"
    },
    {
        "id": 39,
        "question": "inspectionQuestion.inspectionQuestion39",
        "answer": "inspectionQuestion.inspectionAnswer39"
    },
    {
        "id": 40,
        "question": "inspectionQuestion.inspectionQuestion40",
        "answer": "inspectionQuestion.inspectionAnswer40"
    },
    {
        "id": 41,
        "question": "inspectionQuestion.inspectionQuestion41",
        "answer": "inspectionQuestion.inspectionAnswer41"
    },
    {
        "id": 42,
        "question": "inspectionQuestion.inspectionQuestion42",
        "answer": "inspectionQuestion.inspectionAnswer42"
    },
    {
        "id": 43,
        "question": "inspectionQuestion.inspectionQuestion43",
        "answer": "inspectionQuestion.inspectionAnswer43"
    },
    {
        "id": 44,
        "question": "inspectionQuestion.inspectionQuestion44",
        "answer": "inspectionQuestion.inspectionAnswer44"
    },
    {
        "id": 45,
        "question": "inspectionQuestion.inspectionQuestion45",
        "answer": "inspectionQuestion.inspectionAnswer45"
    }
];

export default INSPECTION_QUESTION;