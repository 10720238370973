import React from 'react';
import ReactDOM from 'react-dom';

/** Заранее добавляем шрифты */
import './index.css';
import App from './App';
import 'react-app-polyfill/ie9.js';

import './i18n';

/** 100vh on mobile */
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
/** research after resize */
window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});

ReactDOM.render(
    <App />,
    document.getElementById('root')
);