import React from 'react';
import "./Loading.css";

const Loading = (props) => {
    return (
        <div className={'loader-wrapper'}>
            <div className="loader-container">
                <div className="loader" />
            </div>
        </div>
    )
}

export default Loading;