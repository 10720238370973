/** начальная страница */
export const NOT_START = 0;
/** Welcome страница */
export const INIT = 1;
/** Решаем основные загадки */
export const START = 2;
/** Решили основные загадки - ищем приступника */
export const FINAL = 3;
/** Признание */
export const CONFESSION = 4;
/** Игра завершена */
export const FINISH = 5;