import React, {createContext, useContext, useReducer} from 'react';

import CLUES from "../constants/game/clues";
import * as ACTIONS from "../constants/actions";

import {pushIfNotExist, pushIfNotExistByList} from "../helper/pushIfNotExist";
import {save, clear} from "../helper/save";
/** Название сущности, для удобства в каждом Контексте
 * будет одинаково называться */
const ENTITY = 'clue';
/** Синонимы - чтоб не мучаться и заполнять поле сущность =) */
const entitySave = (data) => save(ENTITY, data);
const entityClear = (data) => clear(ENTITY, data);

const ClueStateContext = createContext();
const ClueDispatchContext = createContext();

function clueReducer(state, action) {
    switch (action.type) {
        case ACTIONS.CLEAR_CLUE :
            return entityClear(init(defaultState));
        case ACTIONS.ADD_CLUE :
            let id = action.payload.id;
            pushIfNotExist(id, CLUES, state);
            return entitySave(state);
        case ACTIONS.ADD_CLUE_BY_IDS :
            let list = action.payload;
            pushIfNotExistByList(list, CLUES, state);
            return entitySave(state);
        case ACTIONS.SET_FROM_STASH :
            let newState = action.payload.state
            return entitySave(newState);
        default:
            //console.log('По логике не должны сюда попадать - что то не так =)');
            return state;
    }
}

const defaultState = [];
const init = (state) => state;
const clueInitialState = JSON.parse(localStorage.getItem(ENTITY)) ?? init(defaultState);

function ClueProvider({children}) {
    const [clue, clueDispatch] = useReducer(clueReducer, clueInitialState, init);
    return (
        <ClueStateContext.Provider value={clue}>
            <ClueDispatchContext.Provider value={clueDispatch}>
                {children}
            </ClueDispatchContext.Provider>
        </ClueStateContext.Provider>
    )
}

function useClueState() {
    return useContext(ClueStateContext);
}

function useClueDispatch() {
    return useContext(ClueDispatchContext);
}

function useClue() {
    return [useClueState(), useClueDispatch()];
}

export {ClueProvider, useClueState, useClueDispatch, useClue}