import CARDS from "../constants/game/cards";
import * as ACTIONS from "../constants/actions";
import * as DISPATCHERS from "../constants/dispatchers";

export function getCard(id){
    /** Если цвета нет - то и делать нечего =) */
    if(id === null){
        return [];
    }

    let card = CARDS.find(el => el.id === id);
    /** Если карты не существует - возвращаме пустоту
     *  TODO: из минусов - нет возможности отловить ошибку в добавлении
     * */
    if(card === undefined){
        return [];
    }

    return [
        {dispatcher : DISPATCHERS.CARD, action : ACTIONS.ADD_CARD, payload : {id : id}},
        {dispatcher : DISPATCHERS.QUESTION, action : ACTIONS.CHECK_QUESTION, payload : {card : card}},
    ];
}