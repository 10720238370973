import ITEMS from "../constants/game/items";
import * as ACTIONS from "../constants/actions";
import * as DISPATCHERS from "../constants/dispatchers";
import {NAV_USAGE_NAME} from "../constants/navigation";
import {createAction} from "./actionHelper";

export function getItem(id){
    if(id === null){
        return [];
    }

    let item = ITEMS.find(el => el.id === id);
    if(item === undefined){
        return [];
    }

    return [
        createAction(DISPATCHERS.ITEM, ACTIONS.ADD_CARD, {id : id}),
        createAction(DISPATCHERS.GAME, ACTIONS.SET_NOTIFICATION, {notificationName : NAV_USAGE_NAME})
    ];
}