import CLUES from "../constants/game/clues";
import * as ACTIONS from "../constants/actions";
import * as DISPATCHERS from "../constants/dispatchers";
import {createAction} from "./actionHelper";

export function getClue(id){
    /** Если цвета нет - то и делать нечего =) */
    if(id === null){
        return [];
    }
    let clue = CLUES.find(el => el.id === id);
    if(clue === undefined){
        return [];
    }
    return [createAction(DISPATCHERS.CLUE, ACTIONS.ADD_CARD, {id : id})];
}

export function getClueByIds(list){
    let pushList = [];

    for(let i = 0; i < list.length; i++){
        let id = list[i];
        if(id === null){
            return [];
        }
        let clue = CLUES.find(el => el.id === id);
        if(clue === undefined){
            return [];
        }
        pushList.push(id);
    }

    return [createAction(DISPATCHERS.CLUE, ACTIONS.ADD_CLUE_BY_IDS, pushList)];
}