import {getCard} from "../../helper/getCard";
import {getItem} from "../../helper/getItem";
import {getClue} from "../../helper/getClue";
import {changeLocation} from "../../helper/changeLocation";
import {actionMessage} from "../../helper/actionMessage";

import * as COLOR from "../../constants/colors";

export const LOCATIONS = [
    {
        id: 1,
        title: 'locations.location1',
        fields: [
            {
                name: 'A1',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S1'}], action: getCard(1)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'B1',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S3'}], action: getCard(3)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'C1',
                important : false,
                inspection: {message: ['messages.flood1', {}], action: null},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'A2',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S2'}], action: getCard(2)},
                itemUsage: {itemId: 2, message: ['service.getCard', {card : 'i3'}], action: getItem(3)}
            },
            {
                name: 'B2',
                important : false,
                inspection: {message: ['messages.flood2', {}], action: null},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'C2',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S4'}], action: getCard(4)},
                itemUsage: {itemId: null, message: '', action: null}
            },
        ],
        action : changeLocation(2, 2, 6, 3, 6),
        actionMessage : actionMessage(2, 2, 6, 3),
        onInspected : null,
        onInspectedStatus : false,
        changeLocationCondition : {
            location : {
                important : true,
            },
            question : {
                decided : [COLOR.GRAY, COLOR.BLACK, COLOR.WHITE],
            },
        },
    },
    {
        id: 2,
        title: 'locations.location2',
        fields: [
            {
                name: 'A1',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S7'}], action: getCard(7)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'B1',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S9'}], action: getCard(9)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'C1',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S11'}], action: getCard(11)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'A2',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S8'}], action: getCard(8)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'B2',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S10'}], action: getCard(10)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'C2',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S12'}], action: getCard(12)},
                itemUsage: {itemId: null, message: '', action: null}
            },
        ],
        action : changeLocation(3, 3, 6, 3, 6),
        actionMessage : actionMessage(3, 3, 6, 3),
        onInspected : null,
        onInspectedStatus : false,
        changeLocationCondition : {
            location : {
                inspected : true,
            },
            question : {
                decided : [COLOR.BROWN, COLOR.ORANGE, COLOR.YELLOW],
            },
        },
    },
    {
        id: 3,
        title: 'locations.location3',
        fields: [
            {
                name: 'A1',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S13'}], action: getCard(13)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'B1',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S15'}], action: getCard(15)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'C1',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S16'}], action: getCard(16)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'A2',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S14'}], action: getCard(14)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'B2',
                important : true,
                inspection: {message: ['service.getCard', {card : 'i2'}], action: getItem(2)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'C2',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S17'}], action: getCard(17)},
                itemUsage: {itemId: null, message: '', action: null}
            },
        ],
        action : changeLocation(4, null, 4, 3, 6),
        actionMessage : actionMessage(4, null, 4, 3),
        onInspected : null,
        onInspectedStatus : false,
        changeLocationCondition : {
            location : {
                inspected : true,
            },
            question : {
                decided : [COLOR.DARK_GREEN, COLOR.GREEN, COLOR.YELLOW],
            },
            item : {
                have : [2],
            }
        },
    },
    {
        id: 4,
        title: 'locations.location4',
        fields: [
            {
                name: 'A1',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S18'}], action: getCard(18)},
                itemUsage: {itemId: 5, message: ['service.getCard', {card : 'S22'}], action: getCard(22)}
            },
            {
                name: 'B1',
                important : false,
                inspection: {message: ['messages.flood3', {}], action: null},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'C1',
                important : false,
                inspection: {message: ['service.getCard', {card : 'S21'}], action: getCard(21)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'A2',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S19'}], action: getCard(19)},
                itemUsage: {itemId: 3, message: ['service.getCard', {card : 'i4'}], action: getItem(4)}
            },
            {
                name: 'B2',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S20'}], action: getCard(20)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'C2',
                important : true,
                inspection: {message: ['messages.flood4', {}], action: null},
                itemUsage: {itemId: null, message: '', action: null}
            },
        ],
        action : changeLocation(5, null, 2, 3, 6),
        actionMessage : actionMessage(5, null, 2, 3),
        onInspected : null,
        onInspectedStatus : false,
        changeLocationCondition : {
            location : {
                important : true,
            },
            question : {
                decided : [COLOR.KHAKI, COLOR.LIGHT_GREEN],
            },
            item : {
                have : [4],
            }
        },
    },
    {
        id: 5,
        title: 'locations.location5',
        fields: [
            {
                name: 'A1',
                important : false,
                inspection: {message: ['messages.flood5', {}], action: null},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'B1',
                important : false,
                inspection: {message: ['messages.flood6', {}], action: null},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'C1',
                important : false,
                inspection: {message: ['messages.flood7', {}], action: null},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'A2',
                important : false,
                inspection: {message: ['messages.flood8', {}], action: null},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'B2',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S23'}], action: getCard(23)},
                itemUsage: {itemId: 4, message: ['service.getCard', {card : 'S24'}], action: getCard(24)}
            },
            {
                name: 'C2',
                important : true,
                inspection: {message: ['service.getCard', {card : 'E11'}], action: getClue(11)},
                itemUsage: {itemId: null, message: '', action: null}
            },
        ],
        action : changeLocation(6, 4, 5, 3, 6),
        actionMessage : actionMessage(6, 4, 5, 3),
        onInspected : null,
        onInspectedStatus : false,
        changeLocationCondition : {
            location : {
                important : true,
            },
            item : {
                used : [4],
            }
        },
    },
    {
        id: 6,
        title: 'locations.location6',
        fields: [
            {
                name: 'A1',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S25'}], action: getCard(25)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'B1',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S27'}], action: getCard(27)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'C1',
                important : true,
                inspection: {message: ['messages.flood9', {}], action: null},
                itemUsage: {itemId: 10, message: ['service.getCard', {card : 'S29'}], action: getCard(29)}
            },
            {
                name: 'A2',
                important : false,
                inspection: {message: ['messages.flood10', {}], action: null},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'B2',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S30'}], action: getCard(30)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'C2',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S28'}], action: getCard(28)},
                itemUsage: {itemId: null, message: '', action: null}
            },
        ],
        action : changeLocation(7, null, 3, 3, 6),
        actionMessage : actionMessage(7, null, 3, 3),
        onInspected : null,
        onInspectedStatus : false,
        changeLocationCondition : {
            location : {
                important : true,
            },
            question : {
                decided : [COLOR.BLUE, COLOR.AQUA, COLOR.LEMONY, COLOR.DARK_BLUE],
            },
        },
    },
    {
        id: 7,
        title: 'locations.location7',
        fields: [
            {
                name: 'A1',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S31'}], action: getCard(31)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'B1',
                important : true,
                inspection: {message: ['messages.flood11', {}], action: null},
                itemUsage: {itemId: 8, message: ['service.getCard', {card : 'S33'}], action: getCard(33)}
            },
            {
                name: 'C1',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S32'}], action: getCard(32)},
                itemUsage: {itemId: 9, message: ['service.getCard', {card : 'E7'}], action: getClue(7)}
            },
        ],
        action : changeLocation(8, null, 5, 3, 3),
        actionMessage : actionMessage(8, null, 5, 3),
        onInspected : null,
        onInspectedStatus : false,
        changeLocationCondition : {
            location : {
                inspected : true,
            },
        },
    },
    {
        id: 8,
        title: 'locations.location8',
        fields: [
            {
                name: 'A1',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S34'}], action: getCard(34)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'B1',
                important : false,
                inspection: {message: ['messages.flood12', {}], action: null},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'C1',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S37'}], action: getCard(37)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'A2',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S35'}], action: getCard(35)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'B2',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S36'}], action: getCard(36)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'C2',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S38'}], action: getCard(38)},
                itemUsage: {itemId: null, message: '', action: null}
            },
        ],
        action : changeLocation(9, 5, 4, 3, 6),
        actionMessage : actionMessage(9, 5, 4, 3),
        onInspected : null,
        onInspectedStatus : false,
        changeLocationCondition : {
            location : {
                important : true,
            },
            question : {
                decided : [COLOR.PINK, COLOR.RED],
            },
        },
    },
    {
        id: 9,
        title: 'locations.location9',
        fields: [
            {
                name: 'A1',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S40'}], action: getCard(40)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'B1',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S41'}], action: getCard(41)},
                itemUsage: {itemId: 1, message: ['service.getCard', {card : 'i8'}], action: getItem(8)}
            },
            {
                name: 'C1',
                important : false,
                inspection: {message: ['messages.flood13', {}], action: null},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'A2',
                important : false,
                inspection: {message: ['messages.flood14', {}], action: null},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'B2',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S42'}], action: getCard(42)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'C2',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S43'}], action: getCard(43)},
                itemUsage: {itemId: null, message: '', action: null}
            },
        ],
        action : changeLocation(10, null, 3, 3, 6),
        actionMessage : actionMessage(10, null, 3, 3),
        onInspected : null,
        onInspectedStatus : false,
        changeLocationCondition : {
            location : {
                important : true,
            },
            question : {
                decided : [COLOR.BURGUNDY, COLOR.PURPLE, COLOR.LILAC, COLOR.TURQUOISE, COLOR.LIGHT_BLUE],
            },
            item : {
                used : [1, 8, 10],
            }
        },
    },
    {
        id: 10,
        title: 'locations.location10',
        fields: [
            {
                name: 'A1',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S45'}], action: getCard(45)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'B1',
                important : false,
                inspection: {message: ['messages.flood15', {}], action: null},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'C1',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S46'}], action: getCard(46)},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'A2',
                important : false,
                inspection: {message: ['messages.flood16', {}], action: null},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'B2',
                important : false,
                inspection: {message: ['messages.flood17', {}], action: null},
                itemUsage: {itemId: null, message: '', action: null}
            },
            {
                name: 'C2',
                important : true,
                inspection: {message: ['service.getCard', {card : 'S47'}], action: getCard(47)},
                itemUsage: {itemId: null, message: '', action: null}
            },
        ],
        action : [],
        actionMessage : [],
        onInspected : null,
        onInspectedStatus : false,
        changeLocationCondition : {}
    },
];

export default LOCATIONS;