import alice_voice_1 from '../../../audio/alice_finn_1.mp3';
import alice_voice_2 from '../../../audio/alice_finn_2.mp3';
import alice_voice_3 from '../../../audio/alice_finn_3.mp3';
import alice_voice_4 from '../../../audio/alice_finn_4.mp3';
import alice_voice_5 from '../../../audio/alice_finn_5.mp3';
import alice_voice_6 from '../../../audio/alice_finn_6.mp3';

import confession_audio from "../../../audio/confession.mp3";
import confession_bg from "../../../img/background/confession-bg.png";
import confession_person from "../../../img/confession-person.png";

import alice_finn_img from "../../../img/persons/alice_finn.png";
import alice_finn_color_img from "../../../img/persons/alice_finn_color.jpg";

const ALICE_FINN = {
    id : 4,
    path : 'alice_finn',
    name: 'Алиса Финн',
    position: 'Техник парка',
    isCriminal : true,
    confession : {
        audio : confession_audio,
        bg : confession_bg,
        person : confession_person,
    },
    img : alice_finn_img,
    imgColor : alice_finn_color_img,
    interrogation: [
        {
            id : 1,
            title: 'interrogations.interrogation41',
            text: 'Совсем недавно. Ещё меньше месяца. Как только мне исполнилось 18, я смогла устроиться на эту работу. Но, не смотря на юный возраст, среди всех этих железок я ощущаю себя как рыба в воде.',
            audio: alice_voice_5
        },
        {
            id : 2,
            title: 'interrogations.interrogation42',
            text: 'С самого раннего детства! Сколько себя помню. Однажды папа подарил мне большой блестящий гаечный ключ. И это был лучший подарок за всю мою жизнь. Мы с папой всегда всё делали вместе. Он научил меня очень многому. У нас с ним была традиция - ходить на выходных в парк аттракционов. И я как заворожённая смотрела на все эти карусели и механизмы. Я мечтала, что когда вырасту, обязательно буду здесь работать. Так что можно сказать, что моя мечта сбылась.',
            audio: alice_voice_3
        },
        {
            id : 3,
            title: 'interrogations.interrogation43',
            text: 'Мне кажется это дико интересным! И меня лично совсем не пугает. Это же целая история и приключение - поработать в парке, который населяют призраки...буууууу. Пхахаха. А если говорить серьезно, то мне главное, чтобы платили, и работа была, остальное меня волнует мало.',
            audio: alice_voice_6
        },
        {
            id : 4,
            title: 'interrogations.interrogation44',
            text: 'Иногда я могу увлечься, засидеться за работой и не заметить, как пролетело время. Я даже завела себе в мастерской будильник, который напоминает мне, что пора бы уже и домой. Но даже если я задерживалась допоздна, то своими глазами ещё ни разу не видела ничего необычного. Работа в мастерской погружает меня в себя с головой.',
            audio: alice_voice_1
        },
        {
            id : 5,
            title: 'interrogations.interrogation45',
            text: 'Толком нет. Но я не в обиде на них, все на ушах из-за этих последних событий. А тут ещё и я. Им просто не до меня. Но я не расстраиваюсь. Я же сюда не за друзьями пришла. А так, мне кажется, что они все довольно милые',
            audio: alice_voice_2
        },
        {
            id : 6,
            title: 'interrogations.interrogation46',
            text: 'У меня даже не было возможности поближе познакомиться с теми сотрудниками, которые сейчас работают в парке. Понятия не имею, кто работал до меня. Слышала лишь то, что долго на этой должности никто не задерживался - объём работы большой. Но меня то это не пугает. Именно за этим я здесь)',
            audio: alice_voice_4
        },
    ]
}

export default ALICE_FINN;