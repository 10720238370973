import * as ROUTER from './route';
import * as GAME_STATE from './gameState';

const WELCOME_GROUP = [
    GAME_STATE.INIT
];

const DESC_GROUP = [
    GAME_STATE.START,
    GAME_STATE.FINAL,
]

const FINAL_GROUP = [
    GAME_STATE.FINAL,
];

const CONFESSION_GROUP = [
    GAME_STATE.CONFESSION,
]

const FINISH_GROUP = [
    GAME_STATE.FINISH,
];

const ALL_ACCESS_GROUP = [
    GAME_STATE.NOT_START,
    GAME_STATE.INIT,
    GAME_STATE.START,
    GAME_STATE.FINAL,
    GAME_STATE.FINISH
];

const RIGHT = {
    [ROUTER.HOME] : {access : ALL_ACCESS_GROUP, defaultRoute : ROUTER.HOME},
    [ROUTER.WELCOME] : {access : WELCOME_GROUP, defaultRoute : ROUTER.HOME},
    [ROUTER.DESC] : {access : DESC_GROUP, defaultRoute : ROUTER.HOME},
    [ROUTER.ANSWERS] : {access : DESC_GROUP, defaultRoute : ROUTER.HOME},
    [ROUTER.ENTER_ANSWER] : {access : DESC_GROUP, defaultRoute : ROUTER.HOME},
    [ROUTER.INSPECTION] : {access : DESC_GROUP, defaultRoute : ROUTER.HOME},
    [ROUTER.INSPECTION_QUESTION] : {access : DESC_GROUP, defaultRoute : ROUTER.HOME},
    [ROUTER.INTERROGATION] : {access : DESC_GROUP, defaultRoute : ROUTER.HOME},
    [ROUTER.INTERROGATION_PERSON] : {access : DESC_GROUP, defaultRoute : ROUTER.HOME},
    [ROUTER.USAGE] : {access : DESC_GROUP, defaultRoute : ROUTER.HOME},
    [ROUTER.SETTING] : {access : DESC_GROUP, defaultRoute : ROUTER.HOME},
    [ROUTER.SELECT_CRIMINAL] : {access : FINAL_GROUP, defaultRoute : ROUTER.HOME},
    [ROUTER.CONFESSION] : {access : CONFESSION_GROUP, defaultRoute : ROUTER.HOME},
    [ROUTER.ACHIEVEMENTS] : {access : FINISH_GROUP, defaultRoute : ROUTER.HOME},
};

export default RIGHT;