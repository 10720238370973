import React, {createContext, useContext, useReducer} from 'react';

import * as ACTIONS from "../constants/actions";

import {createObject} from "../helper/save";

const ActionStateContext = createContext();
const ActionDispatchContext = createContext();

function actionReducer(state, action) {
    switch (action.type) {
        case ACTIONS.CLEAR_ACTIONS :
            return init();
        case ACTIONS.ADD_ACTIONS:
            if(action.payload === null || action.payload === undefined){
                return state;
            }
            action.payload.map(el => state.push(el));
            return createObject(state);
        default:
            //console.log('По логике не должны сюда попадать - что то не так =)');
            return state;
    }
}

const init = () => [];
const actionInitialState = init();

function ActionProvider({children}) {
    const [action, actionDispatch] = useReducer(actionReducer, actionInitialState, init);
    return (
        <ActionStateContext.Provider value={action}>
            <ActionDispatchContext.Provider value={actionDispatch}>
                {children}
            </ActionDispatchContext.Provider>
        </ActionStateContext.Provider>
    )
}

function useActionState() {
    return useContext(ActionStateContext);
}

function useActionDispatch() {
    return useContext(ActionDispatchContext);
}

function useAction() {
    return [useActionState(), useActionDispatch()];
}

export {ActionProvider, useActionState, useActionDispatch, useAction}
