import pol_rayt_1 from '../../../audio/pol_rayt_1_new.mp3';
import pol_rayt_2 from '../../../audio/pol_rayt_2_new.mp3';
import pol_rayt_3 from '../../../audio/pol_rayt_3_new.mp3';
import pol_rayt_4 from '../../../audio/pol_rayt_4_new.mp3';
import pol_rayt_5 from '../../../audio/pol_rayt_5_new.mp3';
import pol_rayt_6 from '../../../audio/pol_rayt_6_new.mp3';

import pol_ryat_img from "../../../img/persons/pol_rait.png";
import pol_ryat_color_img from "../../../img/persons/pol_rait_color.jpg";

const POL_RAYT = {
    id : 1,
    path : 'pol_rayt',
    name : 'Пол Райт',
    position : 'Охраник парка',
    isCriminal : false,
    img : pol_ryat_img,
    imgColor : pol_ryat_color_img,
    interrogation : [
        {
            id : 1,
            title : 'interrogations.interrogation11',
            text : 'Я помню это место ещё до открытия парка. Начал вынужденно подрабатывать на этапах строительства, а как только парк был готов открыть двери для посетителей, занял охранный пост. Никто лучше меня не знает этот парк.',
            audio : pol_rayt_4,
        },
        {
            id : 2,
            title : 'interrogations.interrogation12',
            text : 'Честно сказать, нет и никогда не нравилась. Детские визги, липкие руки от сладкой ваты... Так и норовят куда-то залезть своими ручонками, что-то сломать, откуда-то упасть. Глаз да глаз за ними нужен, а от этого шума голова раскалывается. Когда в парке пустынно, мне спокойнее. Но эта работа нужна мне. Вряд ли кто-то ещё будет терпеть такого старого ворчливого сыча, как я.',
            audio : pol_rayt_1,
        },
        {
            id : 3,
            title : 'interrogations.interrogation13',
            text : 'Я был бы рад списать это на хулиганство мальчишек, которые пробрались ночью в парк и решили попугать друг друга. Но мимо меня ни одна мышь не проскочит. Так что это исключено! За последние время я лишь однажды заметил странное появление человека в ночное время. Наверняка, запись с камер осталась на моем компьютере. Уж не знаю: мистика ли всему виной, но когда я увидел все это происходящее, то пить бросил. С того момента ни-ни.',
            audio : pol_rayt_2,
        },
        {
            id : 4,
            title : 'interrogations.interrogation14',
            text : 'Между нами говоря, из двух братьев мне всегда больше нравился Генри. Всегда подкупали его справедливость и честность в любой ситуации. Генри всегда был человеком, отвечающим за бизнес-процессы. Виктор же был и остаётся творческой эмоциональной натурой. Если он одержим идеей или эмоцией, то он ни перед чем не остановится. За все время знакомства с Виктором я убедился, что он способен на все, даже сломать человеческую судьбу, если судьба встанет на пути к его цели. Но это останется навсегда на его совести. После ухода Генри дела парка пошли на спад. Эмоциональность Виктора во многом играют с ним злую шутку. ',
            audio : pol_rayt_3,
        },
        {
            id : 5,
            title : 'interrogations.interrogation15',
            text : 'По правде говоря, я живу здесь. В один момент в моей жизни всё круто изменилось. Я лишился жилья, небольшого бизнеса, стал выпивать, от меня ушла жена и оставила с последними трусами. Так я оказался здесь. Мне некуда пойти. Я благодарен Генри, который тогда взял меня на работу и разрешил жить в этой сторожке. ',
            audio : pol_rayt_5,
        },
        {
            id : 6,
            title : 'interrogations.interrogation16',
            text : 'Да тут постоянно что-то происходит. То ребёнок переест попкорна, то человека стошнит на американских горках, то мадам внушительных размеров застрянет на карусели... Но если речь о чем-то действительно серьёзном, то было давно одно ужасное событие. С самой верхней кабинки Колеса обозрения упала молодая девушка. Поговаривали, что бедняжка не вынесла разлуки с женихом на кануне свадьбы...было много шуму. С тех пор аттракцион так и стоит заброшенным. У нас не принято вспоминать об этом...',
            audio : pol_rayt_6,
        },
    ]
}

export default POL_RAYT;