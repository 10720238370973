export const HOME = '/';
export const WAITING = '/waiting/';
export const WELCOME = '/welcome/';
export const DESC = '/desc/';

export const ANSWERS = '/answers/';
export const REPLACE_ANSWER_PATH = ':question_id';
export const ENTER_ANSWER = ANSWERS + REPLACE_ANSWER_PATH + '/';

export const INSPECTION = '/inspection/';

export const INTERROGATION = '/interrogation/';
export const REPLACE_PERSON_PATH = ':person_path';
export const INTERROGATION_PERSON = INTERROGATION + REPLACE_PERSON_PATH + '/';

export const USAGE = '/usage/';
export const SETTING = '/setting/';

export const SELECT_CRIMINAL = '/criminal/';
export const CONFESSION = '/confession/';
export const ACHIEVEMENTS = '/achievements/';

export const INSPECTION_QUESTION = '/inspection/question/';

export const TEST = '/test/';